import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Site_Confianca from '../../screen/site_confianca';

export default function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Site_Confianca}/>     
            </Switch>
        </BrowserRouter>
    )
}