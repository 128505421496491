import React from 'react';
import * as S from './styles';
import icon_protegido from "./Imagens/icon_protegido.png";

//----------------------------------------------------
export default function Site_Confianca() {
    

//#################################################################################################
  return (
    <S.body>
        <S.Container>
            <div className='desabilitar'/>


    {/* //--------------------------------------------------------- */} 
            <S.bodyMeio>

                <div className="topo">
                    <a href="http://confianca.grupohermanos.com.br:8086/associacao/areaassociado/"
                        className="topotext">Area do Associado
                    </a>
                </div>

                <section className="banner"> 
                    <div className="banner2">
                        <div className="menuinterior">
                            <div className="logo">       
                                <a href="#sobre">
                                <img src={require("./Imagens/Logo_2018.png")} width="250"/>  
                                </a>
                            </div>

                            <div className="menu">   
                                <nav>
                                    <div className="menumobile">
                                        <img src={require("./Imagens/menu.png")}/>
                                    </div> 
                                    
                                    <ul>
                                        <li><a href="#sobre">Quem Somos</a></li>
                                        <li><a href="#beneficios">Beneficios</a></li>
                                        <li><a href="#rastreamento">Rastreamento</a></li>
                                        <li><a href="#video">Videos</a></li> 
                                        <li><a href="#contato">Contato</a></li>
                                    </ul> 
                                </nav>
                            </div>   
                        </div>
                    </div>
                </section>

                    
                <div id="sobre" className="janela0">
                    <h3>A CONFIANÇA..</h3>      
                
                    É uma Associação de Assistência Veicular <br/> 
                    que está sempre em busca de Benefícios, Economia e Tranquilidade ao seus Associados <br/> 
                    eliminando seus problemas em caso de Roubo, Colisão e demais prejuízos em seus Veículos <br/> 
                    com facilidade e sem burocracia. <br/>
                    
                </div>
                    
                <div className="minijanelas">
                    <div className="mini">
                        
                        <section className="janmini">
                        <img src={icon_protegido}
                            width="35%"
                            height="30%"
                            className="imagjan"/><br/>
                        <div className="minititulo">PROTEÇÃO</div>
                        Estamos sempre em busca<br/>
                        da melhor solução para<br/>
                        eliminar seus prejuizos..
                        
                        </section>
                        
                        <section className="janmini">
                        <img src={require("./Imagens/icon_rast.png")}
                                width="35%"
                                height="30%"
                                className="imagjan"/><br/>

                        <div className="minititulo">
                            RASTREAMENTO
                        </div>

                        Investimos em Tecnologia <br/>
                        para ter mais chances de recuperar
                        seu veiculo em caso de Roubo..

                        </section>

                        <section className="janmini">
                        <img 
                        src={require("./Imagens/icon_benef.png")}
                        className="imagjan"/> <br/>

                        <div className="minititulo">BENEFICIOS</div>
                        Economia para seu Bolso<br/>
                        Aqui você tem Descontos<br/>
                        em acessórios e serviços para seu veículo..

                        </section>
                    </div>
                </div> 
                <div className="imagem1"/>
                    
                <div id="rastreamento" className="box imgmapa">
                    <div className="titulo">
                        RASTREAMENTO
                    </div>

                    <div className="flexbox">             
                        <div className="flex2">
                            <div className="rast3">
                                <ul>     
                                <li>Localize seu Veículo</li> 
                                <li>Acesso direto de seu celular</li>
                                <li>Função de Bloqueio do Veículo</li>
                                <li>Cerca Virtual para Controlar onde o Veículo deve circular</li>
                                <li>Relatório de Rota do Veículo por período</li>
                                <li>Controle de Velocidade</li>
                                </ul>
                    
                                <div className="localize">
                                    <a href="http://conftrack.com.br/" target="_blank">Localize Seu Veiculo Agora!</a>
                                </div>
                            </div>
                        </div>
                            
                        <div className="imgrastreador"/>

                    </div>
                </div>  

                <div id="beneficios" className="beneficios">
                    <div className="benef1">
                        <div className="benef2">
                            <div className="titulo">
                            BENEFÍCIOS
                            <p>São Descontos em diversos estabelecimentos comerciais.</p>
                            </div>
                            
                            <b>Peças para Motocicletas</b>
                            <div className="janela20">
                            <div className="imagbenefi"></div>
                            <div>
                                    <ul>
                                    <li>Pneus</li>
                                    <li>Kit Relação</li>
                                    <li>Revisão Pode ser <b>Gratuita</b></li>
                                    Obs. Somente em algumas Cidades.
                                    </ul>
                            </div>
                            </div>

                            <div className="borda"></div>
                            
                            <b>Peças para Carros</b>
                            <div className="janela22">
                            <div className="imagbenefi2"> </div>
                            <div>
                                    <ul>
                                    <li>Retrovisor</li>
                                    <li>Para-Brisa</li>
                                    <li>Lanternas </li>
                                    </ul>
                            </div>
                            </div>    
                        </div>
                    </div>
                </div>

                <div className="imagem2"/>

                <div id="video" className="box">
                <div className="boxvideo">
                    <div className="titulo">
                        VIDEOS
                    </div>
                    <div className="video">

                        <iframe width="560" height="315" src="https://www.youtube.com/embed/MRL7YAyaDWA?rel=0" frameBorder="0" allow="autoplay; encrypted-media" >
                        </iframe>
                    
                    
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ka2TeOdyyCE?rel=0" frameBorder="0" allow="autoplay; encrypted-media" >
                        </iframe>
                    </div>
                    
                    </div> 
                
                </div>    

                <div id="contato">
                    <div className="contato">
                        <div className="titulo">
                            ENTRE EM CONTATO CONOSCO <br/>
                        </div>

                        <address>
                            <div className="telefone">
                                <div className="tel">
                                    <b>
                                        ARAGUAÍNA-TO <br/> 
                                        <span style={{'fontSize':'16px'}}>
                                            Rua 13 de Outubro, 33<br/>
                                            Bairro Vila Rosario<br/> 
                                        </span>
                                        <a href="tel:6334126372"> 
                                            63 3412 6372</a><br/>
                                        <a href="tel:63992738889">
                                            63 99273 8889
                                        </a> 
                                        <img src={require("./Imagens/icones/ico_whatsap.png")} width="20px"/> 
                                    </b>
                                </div>

                                <div className="tel" style={{"marginTop": "15px"}}>
                                    <b>
                                        PALMAS-TO <br/> 
                                        <a href="tel:6332151898"> 
                                            63 3215 1898
                                        </a>
                                    </b>
                                </div>

                                <div className="tel" style={{"marginTop": "15px"}}>
                                    <b>
                                        IMPERATRIZ-MA<br/>
                                        <a href="tel:9935248778"> 
                                        99 98420 2167
                                        </a> 
                                    </b>
                                </div>

                                <div className="tel" style={{"marginTop": "15px"}}>
                                    <b>motoconfianca@gmail.com</b><br/> 
                                    <b>confpalmas@gmail.com</b><br/> 
                                </div>
                            </div>

                            {/* <div className="forms">
                                <form action="email.php" method="POST">
                                    <input type="text" 
                                            name="nome" 
                                            id="nome"
                                            placeholder="Nome"
                                            required
                                            autoComplete="on"/> <br/> 

                                    <input type="tel" 
                                            name="telefone" 
                                            id="telefone"
                                            placeholder="Telefone"
                                            required 
                                            autoComplete="on"/>  <br/>

                                    <input type="email" 
                                            name="remetente" 
                                            id="email" 
                                            placeholder="Seu email"
                                            required 
                                            autoComplete="on"/> <br/><br/>
                                    
                                    <textarea name="mensagem" id="mensagem" placeholder="Mensagens" style={{"width": "90%"}}/><br/>
                                
                                    
                                    <input 
                                    type="submit" 
                                    value="Enviar" 
                                    id="enviar" 
                                    name="enviar"
                                    style={{'cursor':'pointer','height': '35px','width': '100px'}}
                                    />
                                </form>
                            </div>   */}
                        </address> 
                    </div>
                </div>

                    <iframe className="mapa" src="https://maps.google.com/maps?q=confian%C3%A7a%20prote%C3%A7%C3%A3o&t=k&z=17&ie=UTF8&iwloc=&output=embed" >
                    </iframe>

                    <div className="rodape">        
                        <footer>
                            <div className="iconesocial">
                                    <a href="https://www.facebook.com/confiancaprotecaoveicular/" target="_blank"/> 
                                    <img src={require("./Imagens/icones/facebook-logo.png")} width="30"/> 

                                    <a href="https://www.instagram.com/confiancaprotecao/" target="_blank"/> 
                                    <img src={require("./Imagens/icones/instagram-logo.png")} width="30"/> 
                            </div>
                            
                            <small>
                                &copy; Copyright 2015-2018 | Confiança. Todos os Direitos Reservados.
                            </small>  
                                
                        </footer>
                    </div>
                
            </S.bodyMeio>
                
            <S.Footer>
                    <span>Desenvolvido por - </span> 
                    <span 
                    style={{'color':"#00ff9d",'cursor':'pointer'}}
                    onClick={()=> window.location.href = 'https://wa.me/qr/J6BX3LNBZACLH1'} 
                    >
                        RBento 
                    </span> 
            </S.Footer>
        </S.Container>
    </S.body>
  );
}

