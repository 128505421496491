import styled from 'styled-components';
import imgfundo2 from './Imagens/img_site/fundo2.jpg';
import mapa from './Imagens/img_site/mapa.jpg';
import guincho from './Guincho3.jpg';
import banner from './Imagens/img_site/BANNER.png';
import banner2 from './Imagens/img_site/BANNER2.png';
import icon_2 from './Imagens/icon_2.png';
import rastreamento from './Imagens/rastreamento.png';
import tres3 from './Imagens/img_site/3.jpg';
import icon_oficina12 from './Imagens/icon_oficina12.png';
import icon_oficina1 from './Imagens/icon_oficina1.png';
import icon_oficina13 from './Imagens/icon_oficina13.png';
import icon_oficina from './Imagens/icon_oficina.png';


export const body = styled.div`
display: flex ;
height:100vh ;
width: 100vw;
min-width: 370px ;
zoom: 1.0;
min-zoom: 1.0;
max-zoom: 2.0;
margin: 0 ;
padding: 0 ;
font-size:18px;
justify-content: center ;


button{
  cursor: pointer;
}

`
//--------------------------------------------
export const Container = styled.div`
display:flex;
height:100vh ;
width: 100vw;
min-width: 370px ;
zoom: 1.0;
min-zoom: 1.0;
max-zoom: 2.0;
background-color: black;
flex-direction: column ;
align-items: center ;

select{
  background-color:#5656f7;
  color: white;
  border-radius: 3px ;
}

a{
  text-decoration:none;
  color: black;
  outline:0;
  background:none;
  border:0;
  cursor: pointer;

  &:hover{
    color:#00ff9d;
    }
}

`

//------------------Modal--------------------------------------
export const fundoModal = styled.div`
display: ${props=>props.modalFundo?'flex':'none'} ;
width: 100%;
height:100% ;
font-size:18px;
background-color: black ;
position: fixed ;
opacity: 0.7 ;

`

export const modalInforme = styled.div`
  display: ${props=>props.modalInforme?'flex':'none'} ;
  width: 100vw ;
  max-width:400px ;
  padding: 20px 0px ;
  position: absolute;
  top:300px;
  border-radius: 5px ;
  background-color: black ;
  z-index: 1 ;
  color: white ;
  font-size: 14px ;

`
export const bodyModal = styled.div`
display: flex ;
margin-top: 5px ;
font-size:13px;
min-width: 375px ;

`
export const dados = styled.div`
  display: flex ;
  flex: 1 ;
  min-width:375px ;
  padding: 7px 0 ;
  font-size: 14px ;
  text-transform:capitalize;

`
export const sair = styled.div`
  display: ${props=>props.logSair ? 'flex':'none'} ;
  background-color: red ;
  padding: 0 8px ;
  font-size: 12px ;
  color: white ;
  border-radius: 1px ;
  align-items: center ;
  justify-content: center ;
  cursor: pointer;
  
`

//------------------------------------------------------
export const Menu = styled.div`
display: flex ;
flex: 0.5;
align-items: center ;
width:100%;
margin-bottom: 10px ;
font-size: 14px ;

button{
  background-color: #5656f7;
  margin: 0 10px ;
  padding: 2px 10px ;
  color: white ;
  border-radius: 5px ;

}
`
// --------------------------------------------------------
export const bodyMeio = styled.div` 
display:flex;
width: 100% ;
flex:20 ;
background-color: white ;
color: black ;
justify-content: center ;
flex-direction: column ;

font-family:"Source Sans Pro", sans-serif;


a{
  text-decoration:none;   
}

/*--- Efeito Parallax ---*/
.imagem1, .imgmapa{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
}

.imagem1 {
    min-height: 450px;
    background-image: url(${imgfundo2});
    background-attachment: fixed;

}
.imgmapa {
    min-height: 450px;
    background-image: url(${mapa});

}
/*---------------------------*/
.boxguincho{
    color: white;
    text-align: center;
    padding-top: 50px;
    font-weight: bold;
    font-size: 20px;
}
.boxguincho a{
    color: #ffd800;
}
.guincho{
    width: 100%;
    height: 650px;      
    background-image: url(${guincho});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    
    
}
.banner{
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${banner});
} 
    .banner2{
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${banner2});
}
/*---------------------------*/

.box {
    
    font-weight: bold;
}

.flexbox {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}

.flex1{ 
    flex:1;
}

.flex2{ 
    flex:2;
}

.topo {
    height: 20px;
    background-color: yellowgreen;
    text-align:right;
    font-size: 100%;
    font-weight:bold;
    padding-right: 70px;
    padding-bottom: 2px;
    color:white;
}
.topotext {
    color:white;}

.topotext:hover {
    color: black
}
.area_ass {
    display:flex;
    flex-wrap: wrap;
    color:white;
    margin-top: 10%;
    
}
.area_ass1 {
    flex: auto;
    text-align:center;  
}

.area_ass1 a {
    color:white;   
}
.menuinterior{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
}
.logo {
    flex: 1;
    align-items: center;
    margin: auto;
}
.menumobile {
    display:none;
    margin: 10px;
}
.menu {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
}
.menu ul{
    display: flex;
    margin: 0;
    padding: 0;
}
.menu li{
    list-style: none;
    font-weight: bold;
}
nav a {
    display: block;
    color: white;
    padding: 10px;
    text-transform: uppercase;
    }

nav a:hover {
    background-color: white;
    color: yellowgreen;
}

div ul li {
list-style-image: url(${icon_2});
        }

.janela0 {
    height: auto;
    text-align: center;
    color:#3F3F3F;
    margin:20px;
}
.minijanelas{
    max-width: 1000px;
    margin: auto;

}
.mini {
    display: flex;
    flex-wrap: wrap;
    margin: 30px;
}  

.janmini{
    flex: 1;
    margin: 10px;
    padding: 30px;
    text-align: center;
    font-size: 100%;
    color:#3F3F3F;
    border-style:solid;
    border-width: thin;
    border-color:lightgrey;
    background-color: white;

}
.janmini:hover{
    background-color: royalblue;
    color: white;
}
.imagjan{
    width:100px;
    height:100px;
    margin-bottom: 10px;
}
.minititulo {
    font-weight: bold;

}
/*RASTREAMENTO*/
.rast3{ 
    width: 300px;
    height: 320px;
    padding: 10px;
    border-radius: 20px;
    background: rgb(255,255,255,0.8);
}
.localize {
    width:200px ;
    text-align: center;
    background-color:royalblue;
    padding:10px;
    margin: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.localize:hover {
    background-color:dodgerblue;
}
.localize a {
    color: white;
    font-size:100%;
}

.imgrastreador {
    flex:1;
    background-image: url(${rastreamento});
    background-repeat: no-repeat;
    background-size: 230px;
    margin: 10px;
    width: 230px;
    height: 300px;
   
}
 
.beneficios {
    background-image:url(${tres3});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
}
.benef1 {
    background: rgb(0,0,0,0.6);
    
    
}
.benef2{
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.janela20,
.janela22{
    display: flex;
    width: 100%;
    margin: 20px;
    
}
.borda{
    border: 2px solid #65655F;
    margin-bottom: 20px;
    width: 90%;
    
  }  
  .imagbenefi{
      width: 35%;
      height: 100px;
      background-image: url(${icon_oficina12});
      background-repeat: no-repeat;
      background-size: 100px 90px;
  }
  .imagbenefi:hover{
      background-image: url(${icon_oficina1});
      background-size: 100px 90px;
      background-repeat: no-repeat;
  }
    .imagbenefi2{
      width: 35%;
      height: 100px;
      background-image: url(${icon_oficina13});
      background-repeat: no-repeat;
      background-size: 100px 100px;

  }
  .imagbenefi2:hover{
      background-image: url(${icon_oficina});
      background-size: 100px 100px;
      background-repeat: no-repeat;
  }
/*VIDEO----------------------*/
  .boxvideo {
      width: 100%;
      min-height: 450px;
      background-color: #EFEFEF;
  }
  .video {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      text-align: center;
      margin-top: 10px;
  }

  iframe {
      flex: 1;
      margin: 10px;
  }

  .contato {
      max-width: 1000px;
      margin: auto;
  }
  .titulo{
      text-align: center;
      font-size: 120%;
      padding-top: 20px;
      font-weight: bold;
      margin-bottom: 20px;
  }
  address{
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
    }
  
  .telefone {
      flex: 1;
      min-width: 300px;
      max-width: 300px;
      min-height: 300px;
      max-height: 300px;
      font-size: 100%;
      font-style: normal;
      padding: 15px;
      border-radius: 10px;
      background-color: #efefef;
      margin: auto;
  }
  .tel {
      min-width: 210px;
      max-width: 210px;
      margin: auto;
  }

  .forms {
      flex: 1;
      min-width: 300px;
      max-width: 300px;
      min-height: 300px;
      max-height: 300px;
      padding:15px ;
      font-size: 100%;
      background-color:royalblue;
      border-radius: 10px;
      text-align: center;
      margin: auto;
  }
  .forms input {
      padding: 5px;
      margin-top: 5px;
      font-size: 80%;
      width: 90%;
      height: 25px;
      border: none;
      border-radius: 3px;
  }
  .mapa {
      width: 100%;
      min-width: 330px;
      height: 330px;
      margin: auto;
      margin-top: 20px;
  }

  textarea {
      width: calc(90% + 10px);
      height: 100px;
      resize: none;
  }

.rodape{
    width: 100%;
    background-color:#F5F5F5;
    
}
footer {
    max-width: 1200px;
    margin: auto;
    padding-top:10px ;
    color:black;
    text-align:center;
    }


  @media only screen and (max-width:717px){
      .menumobile{
          display:flex;

      }
      .menu ul {
            display:none;
            flex-direction: column;
            position: absolute;
            left:0;
            background-color: #FFCC29;
            width: 100%;
            text-align: center;
            
      }
      nav a {
          color: black;
      }

      .mini,
      .flexbox,
      .benef,
      address{
          flex-direction: column;

      }
      address,
      .flexbox,
      .benef{
          align-items: center;
      }
      .telefone, .video {
          min-width: 300px;
      }
      .mapa{
        min-width: 330px;
        height: 330px;
        margin-left: 0px;
        margin-right: 0px; 
      }
      .forms {
          margin-top: 20px;
          margin-left: 0px;
          margin-right: 0px;

      }
  }
`
//---############################################################------
export const imagem = styled.div`
display: flex ;
width: 100% ;
align-items: flex-start;
justify-content: center ;


img{
  width: 50% ;
  min-width: 380px ;
}

`
// -------------------------------------------------------
export const ContainerSorteio = styled.div` 
display:${props=>props.playModalSorteio ? 'flex' : 'none'};
width:80%;
position: relative ;
top: 80px;
left: 40px ;
justify-content: center ;
align-items: center ;

`
export const divModal = styled.div`
display:flex;
flex-direction: column;
align-items: center;
padding:10px;
color: #00D594;
background-color:#f2f2f2;
border-radius:3px;
width:100% ;
font-size: 16px ;


`
//-----------------------------------------
export const boxModal = styled.div`
width:100%;
flex-direction:column;
margin:5px;
border-bottom:1px solid #5656f7;

`

export const PesqSorteio = styled.div`
display: flex ;
flex-direction:column;
align-items: center ;

`

export const lista = styled.div` 
flex-direction: column ;


`
//-----------------------------------------------------------

export const modal = styled.div`
display: flex ;
position: absolute ;
max-width: 390px ;
z-index:1 ;
`
// --------------------------------------------------------
export const Footer = styled.div`
display: flex ;
flex: 1 ;
justify-content: center ;
width:100%;
border-top:1px solid #5656f7;
font-size: 11px ;
color: white ;
background-color: black ;

div{
  display: flex ;
  margin-right: 10px ;
  align-items: center ;
  color: white ;
  padding: 10px 0 ;
}

`

// --------------------------------------------------------
export const Salvar = styled.div`
display:flex;
height:30px;
margin:3px;
justify-content:center;

button{
  background-color:${props=>props.detalhesModal ? '#ffbf00':'#00ff9d'};
  font-size: 15px;
  min-width: 150px;
  border:none;
  border-radius:3px;
  color:${props=>props.editModal ? 'black':'white'};

&:hover{
  background-color:#5656F7;
  color:white;
}
}
`

//-----------------------------
export const divButton = styled.div` 
display:flex;
flex-direction: column ;
margin: 10px 0 ;
width:100% ;

span{
  display: flex ;
  justify-content: end ;
  margin-right: 30px ;

  a{
    font-size:14px ;
  }

}
`